<template>
    <div class="col">
        <router-link :to="{ name : info.route }" class="counter__card">
            <div class="counter__card__icon" :class="info.style">
                <i class="eicon" :class="info.icon"></i>
            </div>
            <div class="counter__card__text">
                <h4>{{ info.count }}</h4>
                <p>{{ $t(info.title) }}</p>
            </div>
        </router-link>
    </div>
</template>
<script>
    export default {
        props : ['info']
    }
</script>
