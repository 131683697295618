<template>
    <section class="counter-box section-gap-30">
        <DashboardStatsLoader v-if="isContentLoading"></DashboardStatsLoader>
        <div class="row row-cols-md-4" v-else>
            <info-counter-box :info="activeCandidates"></info-counter-box>
            <info-counter-box :info="publishedJobs"></info-counter-box>
            <info-counter-box :info="draftJobs"></info-counter-box>
            <info-counter-box :info="teamMembers"></info-counter-box>
        </div>
    </section>
</template>
<script>
    import InfoCounterBox from "./InfoCounterBox";
    import client from '../../app/api/Client';
    import { mapState } from 'vuex';
    import { SWITCHED_TO_ANOTHER_ACCOUNT } from '../../constants/events';
    import { EventBus } from '../../extra/event-bus';
    import DashboardStatsLoader from "../_loaders/DashboardStatsLoader";

    export default {
        components : {
            InfoCounterBox,
            DashboardStatsLoader
        },
        data() {
            return {
                isContentLoading : false,
                activeCandidates : {
                    count : 0,
                    title : "Active Candidates",
                    style : 'gradient-danger',
                    icon : 'e-users',
                    route : 'company.candidates'
                },
                publishedJobs : {
                    count : 0,
                    title : "Active Jobs",
                    style : 'gradient-love',
                    icon : 'e-briefcase',
                    route : 'jobs.published'
                },
                draftJobs : {
                    count : 0,
                    title : "Draft Jobs",
                    style : 'gradient-warning',
                    icon : 'e-briefcase',
                    route : 'jobs.draft'
                },
                teamMembers : {
                    count : 0,
                    title : "Team Members",
                    style : 'gradient-primary',
                    icon : 'e-users-team',
                    route : 'company.setting.user'
                }
            }
        },
        computed : {
            ...mapState(['company']),
            sections() {
                return Object.keys(this.stats);
            }
        },
        methods : {
            async loadCompanyState() {
                this.isContentLoading = true;
                try {
                    let {data : {data}} = await client().get('/company/stats');
                    this.teamMembers.count =  data.managers;
                    this.draftJobs.count = data.draft_jobs;
                    this.publishedJobs.count = data.published_jobs;
                    this.activeCandidates.count = data.active_candidates;
                } catch(e) {
                }
              this.isContentLoading = false;
            }
        },
        mounted() {
            EventBus.$on(SWITCHED_TO_ANOTHER_ACCOUNT, (accountType) => {
                if (accountType === 'company') {
                    this.loadCompanyState();
                }
            });
        },
        created() {
            this.loadCompanyState();
        },
        beforeDestroy() {
            EventBus.$off(SWITCHED_TO_ANOTHER_ACCOUNT);
        }
    }
</script>
